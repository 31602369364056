import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import store from './config/configureStore';
import { history } from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import './assets/base.scss';
import './icons';
import RMSAddModal from 'components/RMSAddModal';
import { LicenseInfo } from '@material-ui/x-grid';
import { getDictionaries } from 'reducers/DictionaryReducer';
import { updateZones } from 'reducers/ZonesReducer';
import { getUserSettings } from 'reducers/UserReducer';
import RMSRecordsDialogs from 'components/RMSRecordsDialogs';
import { getUserPermissions } from 'reducers/PermissionsReducer';
import { subscribeKeyboard, unsubscribeKeyboard } from 'reducers/KeyboardReducer';
import { useLoadScript } from '@react-google-maps/api';
import {
  subscribeBulletins,
  unsubscribeBulletins,
  findUnreadUserBulletins,
} from 'reducers/BulletinReducer';
import { getServerTimeSettings, timeSyncInit } from 'reducers/TimeReducer';
import { notifyPanel } from 'reducers/NotifierReducer';
import { gerReportsAllList, gerReportsList } from 'reducers/ReportsReducer';
import { setWorkflowStepRoles } from 'reducers/WorkFlowReducer';
import { getAllDuplicatesParams } from 'reducers/DuplicateReducer';

LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const mapLibraries = (process.env.REACT_APP_GOOGLE_LIBRARIES || '').split(',');

const App = (props) => {
  const snackbarRef = useRef();
  const [mapError, setMapError] = useState(false);
  const { showSpinner } = props.ui;
  const {
    websocket,
    authenticated,
    getUserPermissions,
    getUserSettings,
    updateZones,
    getDictionaries,
    setWorkflowStepRoles,
    getAllDuplicatesParams,
    gerReportsAllList,
    gerReportsList,
    subscribeKeyboard,
    unsubscribeKeyboard,
    subscribeBulletins,
    unsubscribeBulletins,
    getServerTimeSettings,
    timeSyncInit,
    findUnreadUserBulletins,
  } = props;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
    libraries: mapLibraries,
  });
  if (loadError && !mapError) setMapError(true);

  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const updateApp = (registration) => {
    window.localStorage.clear();
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          // window.location.reload();
          window.location.href = '/home';
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  useEffect(() => {
    const openNotification = (registration) => {
      const notification = {
        title: `Application Update Available!`,
        message: `New release of RMS Application has been released.It is highly recommended to launch the new version!!`,
        position: 'tc',
        level: 'warning',
        autoDismiss: 0,
        action: {
          label: 'LAUNCH!',
          callback: () => updateApp(registration),
        },
      };
      props.notifyPanel(notification, 'success');
    };

    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: (reg) => openNotification(reg),
    });
  }, []);

  useEffect(() => {
    if (websocket.websocket && authenticated) {
      subscribeKeyboard();
      getUserPermissions();
      getUserSettings();
      updateZones();
      getDictionaries();
      getAllDuplicatesParams();
      setWorkflowStepRoles();
      gerReportsAllList();
      gerReportsList();
      timeSyncInit();
      getServerTimeSettings();
      subscribeBulletins();
      findUnreadUserBulletins();
    }
    return () => {
      if (websocket.websocket && authenticated) {
        unsubscribeKeyboard();
        unsubscribeBulletins();
      }
    };
  }, [websocket, authenticated]);

  const closeSnackbar = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
              <SnackbarProvider
                ref={snackbarRef}
                action={(key) => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <RMSAddModal />
                <AnimatePresence>
                  <Routes />
                </AnimatePresence>
                <RMSRecordsDialogs />
              </SnackbarProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  mode: state.theme.mode,
  ui: state.ui,
  websocket: state.websocket,
  authenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps, {
  getUserPermissions,
  getUserSettings,
  updateZones,
  getDictionaries,
  setWorkflowStepRoles,
  getAllDuplicatesParams,
  gerReportsAllList,
  gerReportsList,
  subscribeKeyboard,
  unsubscribeKeyboard,
  subscribeBulletins,
  findUnreadUserBulletins,
  unsubscribeBulletins,
  notifyPanel,
  timeSyncInit,
  getServerTimeSettings,
})(App);
