import store from '../config/configureStore';

import { getService } from './service';
import { downloadAttachmentsAsZip } from 'utils/functions';

const REPORT_SELECTION_DIALOG = 'REPORT_SELECTION_DIALOG';
const REPORT_PRINT_DIALOG = 'REPORT_PRINT_DIALOG';
const SELECTION_DIALOG_DATA = 'SELECTION_DIALOG_DATA';
const PRINT_DIALOG_DATA = 'PRINT_DIALOG_DATA';
const REPORT_DATA = 'REPORT_DATA';
const REPORT_PRINT_INDEX = 'REPORT_PRINT_INDEX';
const ARRESTEE_INDEX = 'ARRESTEE_INDEX';
const SET_REPORTS_LIST = 'SET_REPORTS_LIST';
const SET_ALL_REPORTS_LIST = 'SET_ALL_REPORTS_LIST';

const defaultState = {
  selectionDialog: null,
  printDialog: null,
  selectionDialogData: null,
  printDialogData: null,
  reportsData: null,
  printReportIndex: null,
  arresteeIndex: null,
  allReportList: [],
  reportsList: [],
};

export const openSelectionDialog = () => (dispatch) =>
  dispatch({ type: REPORT_SELECTION_DIALOG, data: true });
export const closeSelectionDialog = () => (dispatch) =>
  dispatch({ type: REPORT_SELECTION_DIALOG, data: false });

export const openPrintDialog = () => (dispatch) =>
  dispatch({ type: REPORT_PRINT_DIALOG, data: true });
export const closePrintDialog = () => (dispatch) =>
  dispatch({ type: REPORT_PRINT_DIALOG, data: false });

export const setSelectionDialogData = (data) => (dispatch) =>
  dispatch({ type: SELECTION_DIALOG_DATA, data });

export const setPrintDialogData = (data) => (dispatch) =>
  dispatch({ type: PRINT_DIALOG_DATA, data });

export const setReportsData = (data) => (dispatch) => dispatch({ type: REPORT_DATA, data });

export const setReportPrintIndex = (data) => (dispatch) =>
  dispatch({ type: REPORT_PRINT_INDEX, data });

export const setArresteeIndex = (data) => (dispatch) => dispatch({ type: ARRESTEE_INDEX, data });

export const printReport = () => {
  return (dispatch) => {
    const reportState = store.store.getState().reports;
    const { printDialogData, printReportIndex, selectionDialogData, arresteeIndex } = reportState;
    const userClearanceLevel = store.store.getState().user.userCurrentClearanceLevel.Level;

    let level = parseInt(userClearanceLevel);
    let id = parseInt(printDialogData?.Reports[printReportIndex]);
    let data = {};

    if (id !== 289) {
      data = {
        ptsIncidentID: selectionDialogData?.ptsIncidentId,
      };
    }

    if (id === 247) {
      data.AgencyID = selectionDialogData?.agencyId;
      data.Priority = level;
      data.ReportingOfficer = selectionDialogData?.officer;
      data.Callers = printDialogData?.Callers;
      data.Persons = printDialogData?.Persons;
      data.Places = printDialogData?.Places;
      data.Offenses = printDialogData?.Offenses;
      data.Properties = printDialogData?.Properties;
      data.Narratives = printDialogData?.Narratives;
    } else if (id === 248) {
      data.AgencyID = selectionDialogData?.agencyId;
      data.Priority = level;
      data.ReportingOfficer = selectionDialogData?.officer;
      data.Callers = printDialogData?.Callers;
      data.Persons = printDialogData?.Persons;
      data.Places = printDialogData?.Places;
      data.Offenses = printDialogData?.Offenses;
      data.Properties = printDialogData?.Properties;
      data.Evidences = printDialogData?.Evidences;
      data.Narratives = printDialogData?.Narratives;
    } else if (id === 249) {
      if (printDialogData?.MissingPerson) data.ptsIncPersonID = printDialogData?.MissingPerson;
      data.AgencyID = selectionDialogData?.agencyId;
      data.ReportingOfficer = selectionDialogData?.officer;
    } else if (id === 265) {
      if (printDialogData?.DVVictimPerson) {
        data.ptsIncPersonID = printDialogData?.DVVictimPerson.ptsIncPersonID;
        data.ptsVictimID = printDialogData?.DVVictimPerson.ptsVictimID;
      }
      data.AgencyID = selectionDialogData?.agencyId;
      data.Priority = level;
    } else if (id === 266) {
      if (printDialogData?.Evidences) {
        data.Evidences = printDialogData?.Evidences;
      }
      data.Priority = level;
      data.AgencyID = selectionDialogData?.agencyId;
    } else if (id === 268) {
      if (printDialogData?.Arrestees[arresteeIndex]) {
        data.ptsIncPersonID = printDialogData?.Arrestees[arresteeIndex];
      }
      data.AgencyID = selectionDialogData?.agencyId;
      data.ClearanceLevel = level;

      dispatch(setArresteeIndex(arresteeIndex + 1));
    } else if (id === 267) {
      data.AgencyID = selectionDialogData?.agencyId;
      data.ClearanceLevel = level;
      data.ReportingOfficer = selectionDialogData?.officer;
      if (printDialogData?.Attachments) {
        if (printDialogData?.downloadAttachments === true)
          downloadAttachmentsAsZip(printDialogData?.Attachments, selectionDialogData?.customId);
        data.Incidents = printDialogData?.incAttachment;
        data.Persons = printDialogData?.partyAttachment;
        data.Properties = printDialogData?.propertyAttachment;
        data.Evidences = printDialogData?.evidenceAttachment;
        data.Narratives = printDialogData?.narrativeAttachment;
      }
    } else if (id === 287) {
      data.AgencyID = selectionDialogData?.agencyId;
      data.clearanceLevel = level;
      data.ReportingOfficer = selectionDialogData?.officer;
      data.Callers = printDialogData?.Callers;
      data.Persons = printDialogData?.Persons;
      data.Places = printDialogData?.Places;
      data.Offenses = printDialogData?.Offenses;
      data.Properties = printDialogData?.Properties;
      data.Evidences = printDialogData?.Evidences;
      data.Narratives = printDialogData?.Narratives;
      data.cadNotes = printDialogData?.includeCadNotes;
    } else if (id === 289) {
      if (printDialogData?.Evidences) {
        data.Evidences = printDialogData?.Evidences;
      }
      data.AgencyID = selectionDialogData?.agencyId;
      data.ClearanceLevel = level;
    }

    dispatch(setReportsData({ id, data }));

    if (id === 249 && !printDialogData?.MissingPerson) return;
    if (id === 265 && !printDialogData?.DVVictimPerson) return;
    if (id === 266 && printDialogData?.Evidences.length === 0) return;
    if (id === 289 && printDialogData?.Evidences.length === 0) return;
    if (id === 268 && printDialogData?.Arrestees.length === 0) return;
    if (id === 267 && printDialogData?.Attachments.length === 0) return;
    dispatch(openPrintDialog());
  };
};

export const gerReportsList = () => {
  return async (dispatch) => {
    const service = getService('reportcatalog');
    let reports = await service.find({
      query: {
        Type: 'RMSI',
        IsDeleted: 0,
        $limit: 0,
        $select: ['ReportTitle', 'Type', 'ptsReportID', 'ReportID'],
      },
    });

    dispatch({ type: SET_REPORTS_LIST, data: reports.data });
  };
};

export const gerReportsAllList = () => {
  return async (dispatch) => {
    const service = getService('reportcatalog');
    let reports = await service.find({
      query: {
        Type: 'RMSR',
        IsDeleted: 0,
        $limit: 0,
      },
    });
    dispatch({ type: SET_ALL_REPORTS_LIST, data: reports.data });
  };
};
export const getReport = (reportId, data) => {
  const service = getService('ssrs');
  service.timeout = 90000;
  return service.get(reportId, {
    query: { data },
  });
};

export const sendRipAndRun = (ptsEventID) => {
  const service = getService('rip-and-run');
  service.timeout = 90000;
  return service.create({
    ptsEventID,
  });
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case REPORT_SELECTION_DIALOG:
      return { ...state, selectionDialog: action.data };
    case REPORT_PRINT_DIALOG:
      return { ...state, printDialog: action.data };
    case SELECTION_DIALOG_DATA:
      return { ...state, selectionDialogData: action.data };
    case PRINT_DIALOG_DATA:
      return { ...state, printDialogData: action.data };
    case REPORT_DATA:
      return { ...state, reportsData: action.data };
    case REPORT_PRINT_INDEX:
      return { ...state, printReportIndex: action.data };
    case ARRESTEE_INDEX:
      return { ...state, arresteeIndex: action.data };
    case SET_REPORTS_LIST:
      return { ...state, reportsList: action.data };
    case SET_ALL_REPORTS_LIST:
      return {
        ...state,
        allReportList: action.data,
      };
    default:
      break;
  }
  return state;
}
