import { getService } from './service';

export const DUPLiCATE_PERSON_FORM = 'DUPLICATE/DUPLiCATE_PERSON_FORM';
export const GET_ALL_DUPLICATES_PARAMS = 'DUPLICATE/GET_ALL_DUPLICATES_PARAMS';

const duplicateEntity = ['PerDuplicate', 'AddDuplicate'];
export const getAllDuplicatesParams = () => {
  return async (dispatch) => {
    const service = getService('cdr');
    const paramsValue = await service.find({
      query: {
        CDRKey: {
          $in: duplicateEntity,
        },
        ValueBoolean: true,
      },
    });
    // group by CDRKey
    const params = paramsValue.reduce((acc, cur) => {
      const key = cur.CDRKey.toLowerCase();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(cur);
      return acc;
    }, {});

    dispatch({
      type: GET_ALL_DUPLICATES_PARAMS,
      payload: params,
    });
  };
};

export default function reducer(
  state = {
    dulicatePersonForm: {},
    duplicatesParams: {},
  },
  action
) {
  switch (action.type) {
    case DUPLiCATE_PERSON_FORM:
      return {
        ...state,
        dulicatePersonForm: action.payload,
      };
    case GET_ALL_DUPLICATES_PARAMS:
      return {
        ...state,
        duplicatesParams: action.payload,
      };
    default:
      break;
  }
  return state;
}
