import React, { useRef, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getPersonContactInfo, getPartyPerson } from 'reducers/helpers/recordHelper';
import { handleError } from 'reducers/ErrorReducer';
import {
  setNewCreatedPesonAsSelected,
  showAddPerson,
  showEditPerson,
  showWarrants,
} from 'reducers/PersonDialogsReducer';
import { displayDate } from 'reducers/TimeReducer';
import { IconButton, TextField } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import TextFieldPlus from 'components/RMSTextField/TextFieldPlus';
import { searchPeople2 } from 'reducers/SearchReducer';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from 'components/Tooltip';
import { getPersonWarrant } from 'reducers/WarrantReducer';
import ErrorIcon from '@material-ui/icons/Error';
import clsx from 'clsx';
import { CodeTagTypeIconRender } from 'utils/functions';
import { getFullPermissions } from 'reducers/PermissionsReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    position: 'relative',
    '& .MuiAutocomplete-clearIndicator': {
      visibility: 'visible',
      opacity: 0.2,
      transition: 'all 0.1s',
    },
    '&:hover, & .Mui-focused': {
      '& $personInfo': {
        opacity: 1,
      },
      '& $warrants': {
        opacity: 1,
      },
      '& .MuiAutocomplete-clearIndicator': {
        opacity: 1,
      },
    },
  },
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  personInfo: {
    position: 'absolute',
    right: 60,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s',
  },
  warrants: {
    position: 'absolute',
    right: 90,
    top: 5,
    opacity: 0.2,
    transition: 'all 0.1s',
  },
  iconButtonError: {
    color: theme.palette.error.main,
  },
  iconButton: {
    color: theme.palette.secondary.main,
  },
}));

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0,
};

function PersonLookup2(props) {
  const {
    label = 'Search Person',
    defaultOptions,
    dictionary,
    dialogs,
    ptsPersonID,
    setPerson,
    error = false,
    helperText = '',
    getPersonData = true,
    personIdFromNewCreatedPerson,
    isClearAprrovalClickedFromIncidentNarrative = false,
    setIsClearAprrovalClickedFromIncidentNarrative,
    multiple = false,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const mountedRef = useRef(true);
  const throttleRef = useRef(0);

  const [text, setText] = useState('');
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(multiple ? [] : null);
  const [warrantsNo, setWarrantsNo] = useState(0);
  const [warrants, setWarrants] = useState([]);
  const addEnabled = dialogs.addPerson === null;
  const editEnabled = dialogs.editPerson === null;
  const personPermission = getFullPermissions('globals', 'People', 'any');
  const offset = 0;
  const limit = 100;

  // Effects
  useEffect(() => {
    if (isClearAprrovalClickedFromIncidentNarrative) {
      onSearchSelect(null, null); // when user will clear approval person lookup field will be clear

      if (setIsClearAprrovalClickedFromIncidentNarrative)
        setIsClearAprrovalClickedFromIncidentNarrative(false); // updating for consistancy
    }
  }, [isClearAprrovalClickedFromIncidentNarrative]);

  useEffect(() => {
    searchPerson();
    // eslint-disable-next-line
  }, [text]);

  useEffect(() => {
    if (personData && !multiple) {
      getWarrants(personData.ptsPersonID);
    } else {
      setWarrantsNo(0);
    }
    // eslint-disable-next-line
  }, [personData]);

  useEffect(() => {
    if (!defaultOptions) return;
    setSearchMatch(defaultOptions);
    // eslint-disable-next-line
  }, [defaultOptions]);

  useEffect(() => {
    if (ptsPersonID !== undefined) {
      if (personIdFromNewCreatedPerson) {
        getPersonWithDetails();
      } else {
        getPerson(ptsPersonID);
      }
    }
    // eslint-disable-next-line
  }, [ptsPersonID]);

  useEffect(() => {
    handlePersonSet();
  }, [setPerson]);

  //Functions
  const getPerson = async (ptsPersonID) => {
    if (!ptsPersonID) return;
    try {
      const personData = await getPartyPerson(ptsPersonID);
      if (!mountedRef.current) return;
      setSearchMatch([personData]);
      setPersonData(personData);
      if (getPersonData) onSearchSelect(null, personData);
    } catch (err) {
      // handleError(err);
    }
  };
  const getPersonWithDetails = async () => {
    searchPeople2(text, false, offset, limit, ptsPersonID)
      .then((result) => {
        result = result.map((p) => ({ ...p, BirthDate: displayDate(p.BirthDate) }));
        setSearchMatch(result);

        setPersonData(result[0]);
        if (getPersonData) onSearchSelect(null, result[0]);
        dispatch(setNewCreatedPesonAsSelected(null));
      })
      .catch(props.handleError);
  };
  const searchPerson = () => {
    if (text.length < 3) {
      setSearchMatch([]);
      return;
    }
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      searchPeople2(text, false, offset, limit)
        .then((result) => {
          result = result.map((p) => ({ ...p, BirthDate: displayDate(p.BirthDate) }));
          setSearchMatch(result);
        })
        .catch(props.handleError);
    }, 500);
  };

  const onPersonChange = (data) => {
    if (props.onPersonChange) return props.onPersonChange(data); // ToDo: should be refactored
    if (props.onChange) return props.onChange(data);
  };

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setPersonData(multiple ? [] : null);
      onPersonChange(null);
      return;
    }
    setWarrantsNo(0);
    setWarrants([]);
    setPersonData(selection);
    if (multiple) {
      onPersonChange(selection);
    } else {
      getPersonContactInfo(selection.ptsPersonID)
        .then((result) => {
          if (!mountedRef.current) return;
          onPersonChange({ ...selection, ContactInfo: result });
        })
        .catch((err) => props.handleError(err, 'Cannot contact API'));
    }
  };

  const getOptionSelected = (option, value) => {
    return option.ptsPersonID === value.ptsPersonID;
  };

  const getWarrants = async (ptsPersonID) => {
    try {
      const warrants = await getPersonWarrant(ptsPersonID);
      if (!warrants || !warrants.length) return;

      setWarrantsNo(warrants[0].Count);
      setWarrants(warrants);
    } catch (err) {
      props.handleError(err);
    }
  };

  // If person set by parent component
  const handlePersonSet = (person = setPerson) => {
    if (!person) {
      if (personData) {
        setSearchMatch([]);
        setPersonData(multiple ? [] : null);
      }
      return;
    } else if (!person.ptsPersonID) return;
    const autocompleteOptions = [{ ...person }];
    setSearchMatch(autocompleteOptions);
    setPersonData(autocompleteOptions[0]);
    if (person !== setPerson) {
      props.onChange(person);
    }
  };
  const renderOption = (option) => {
    let label = '';
    if (option.LastName) label += option.LastName;
    if (option.Suffix) label += ` ${option.Suffix},`;
    if (!option.Suffix) label += ',';
    if (option.FirstName) label += ` ${option.FirstName} `;
    if (option.MiddleName) label += ` ${option.MiddleName} `;
    label += ' | ';
    if (option.Race) {
      const desc = dictionary.codeRace.find((s) => s.Code === option.Race);
      if (desc) label += ' Race: ' + desc.Description + ' | ';
    }
    if (option.Sex) {
      const desc = dictionary.codeSex.find((s) => s.Code === option.Sex);
      if (desc) label += ' Sex: ' + desc.Description + ' | ';
    }
    if (option.BirthDate) {
      label += ' DOB: ' + option.BirthDate + ' | ';
    }
    if (option.OLN) label += ' OLN: ' + option.OLN + ' | ';
    if (option.SSN) label += ' SSN: ' + option.SSN + ' | ';
    if (option.PersonID) label += ' ' + option.PersonID;

    let tagIcon = '';
    if (option.Tags) {
      const json_data = JSON.parse(option.Tags);
      tagIcon = (
        <span>
          {json_data.map((tag, idx) => {
            const icon = tag.c[0].Icon;
            const tagData = {
              created: tag.Created,
              createdBy: tag.CreatedBy,
              updated: tag.Updated,
              updatedBy: tag.UpdatedBy,
              type: tag.Type,
              typeDescription: tag.c[0].TypeDescription,
              description: tag.Value,
            };
            const TagIcon = CodeTagTypeIconRender(icon, 'small', '20px', tagData);
            return <span key={idx}>{TagIcon}</span>;
          })}
        </span>
      );

      label += ' | ';
    }

    return (
      <span className={option.ActiveWarrant ? classes.activeWarrant : classes.option}>
        {label}
        {tagIcon}
      </span>
    );
  };

  const startAdornment =
    addEnabled && !props.disabled && personPermission.Create ? (
      <IconButton style={btnStyle} onClick={() => props.showAddPerson({ origin: props.origin })}>
        <AddIcon fontSize="small" />
      </IconButton>
    ) : undefined;

  const renderPersonInfo = () => {
    const onClick = () => {
      props.showEditPerson(personData.ptsPersonID);
    };

    return (
      <div className={classes.personInfo}>
        <Tooltip title="Person info">
          <span>
            <IconButton
              color="secondary"
              size="small"
              onClick={onClick}
              className={classes.iconButton}>
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  const renderWarrants = () => {
    const onClick = () => {
      const { FirstName, LastName } = personData;
      const Name = `${FirstName} ${LastName}`;
      props.showWarrants({ Name, warrants });
    };
    return (
      <div className={classes.warrants}>
        <Tooltip title="Active warrants">
          <span>
            <IconButton
              color="secondary"
              size="small"
              onClick={onClick}
              className={classes.iconButtonError}>
              <ErrorIcon />
            </IconButton>
          </span>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={clsx(classes.wrap, props.className)} style={props.style}>
      <Autocomplete
        disabled={props.disabled}
        fullWidth
        autoHighlight
        autoSelect={false}
        multiple={multiple}
        options={searchMatch}
        value={personData}
        getOptionLabel={(option) => option.FullName || ''}
        size="small"
        onChange={onSearchSelect}
        onInputChange={(ev, val) => setText(val)}
        renderInput={(params) => {
          params.label = label;
          params.variant = 'outlined';
          return multiple ? (
            <TextField
              autoComplete="off"
              size="small"
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              label={label}
              variant="outlined"
              fullWidth
            />
          ) : (
            <>
              <TextFieldPlus
                {...params}
                error={error}
                helperText={helperText}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'off',
                  startAdornment,
                }}
              />

              {Boolean(personData) && editEnabled && renderPersonInfo()}
              {warrantsNo > 0 && renderWarrants()}
            </>
          );
        }}
        getOptionSelected={getOptionSelected}
        renderOption={renderOption}
        filterSelectedOptions={multiple ? true : false}
        filterOptions={(option) => option}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    dialogs: state.dialogs,
    dataUpdate: state.dataUpdate,
  };
};

export default connect(mapStateToProps, {
  // handleError,
  showAddPerson,
  showWarrants,
  showEditPerson,
})(PersonLookup2);
